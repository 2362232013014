<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "app",
  data() {
    return {
      // 设备
      client: this.MixinClientBrowser(),
      // token
      token: this.$store.state.token,
      // uuid
      cookieId: this.$store.state.cookieId,
    };
  },
  watch: {
    $route() {
      document.title = this.$route.meta.title;
      document.getElementById("app").scroll(0, 0);
    },
  },
};
</script>
