<template>
  <van-overlay :show="showPop">
    <div class="share-pop">
      <img class="icon-share" src="../../assets/images/icon_share.png" alt="" />
      <div class="share-font-box">
        <div class="share-font">
          链接无法打开？<br />点击右上角“...”，选择在浏览器中打开。
        </div>
        <div
          class="share-btn u-flex u-row-center u-col-center"
          @click.stop="handleClose"
        >
          我知道啦
        </div>
      </div>
    </div>
  </van-overlay>
</template>

<script>
export default {
  name: "SharePop",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showPop: false,
    };
  },
  watch: {
    show(val) {
      this.showPop = val;
    },
  },
  methods: {
    handleClose() {
      this.showPop = false;
      this.$emit("change", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.share-pop {
  color: #fff;
  font-size: 0.32rem;
}
.icon-share {
  display: block;
  width: 3.38rem;
  height: 4.8rem;
  position: absolute;
  right: 0.44rem;
  top: 0.34rem;
}
.share-font-box {
  width: 100%;
  padding: 0 0.88rem;
  position: absolute;
  top: 5.2rem;
  left: 0;
}
.share-font {
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  line-height: 0.44rem;
}

.share-btn {
  width: 1.76rem;
  height: 0.72rem;
  margin: 0 auto;
  border: 1px solid #ffffff;
  border-radius: 4px;
  margin-top: 0.6rem;
}
</style>
