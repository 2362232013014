export default {
  data() {
    return {
      client: this.MixinClientBrowser(),
    };
  },
  methods: {
    /** 判断当前是IOS或安卓 */
    MixinClientBrowser() {
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        //ios
        return "ios";
      } else if (/(Android)/i.test(navigator.userAgent)) {
        //android
        return "android";
      }
    },
    // 判断是否是在微信浏览器打开
    isWeixin() {
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
      } else {
        return false;
      }
    },
    // 获取token
    handleToken() {
      return new Promise((resolve) => {
        const { client } = this;
        if (client == "ios") {
          this.$bridge.callhandler("getIosToken", {}, (token) => {
            resolve(token);
          });
        } else if (client == "android") {
          if (window.LoanMarketChina) {
            let token = window.LoanMarketChina.getAuthorization();
            resolve(token);
          }
        }
      });
    },
    /**
     * 调用客户端方法，展示标题栏右上角文案
     * @param {*} title 右上角标题
     * @param {*} url 右上角跳转H5链接
     */
    setRightBtn(title, url) {
      const { client } = this;
      if (client === "ios") {
        try {
          this.$bridge.callhandler("setRightBtn", { title, url });
        } catch (err) {
          console.log("try---catch-调用IOS客户端方法报错");
        }
      } else if (client === "android") {
        if (window.LoanMarketChina) {
          try {
            window.LoanMarketChina.setRightBtn(title, url);
          } catch (err) {
            console.log("try---catch-调用安卓客户端方法报错");
          }
        }
      }
    },

    /*
     *@描述: 调用客户端方法，设置title(用于 三方签约 回调结果页面设置title)
     *@参数: title: 页面展示的标题
     *@作者: 金涛
     *@日期: 2022-08-09 10:26:13
     */
    setPageTitle(title) {
      const { client } = this;
      if (client === "ios") {
        try {
          this.$bridge.callhandler("setActionBarTitle", { title });
        } catch (err) {
          console.log("try---catch-调用IOS客户端方法报错");
        }
      } else if (client === "android") {
        if (window.LoanMarketChina) {
          try {
            window.LoanMarketChina.setActionBarTitle(title);
          } catch (err) {
            console.log("try---catch-调用安卓客户端方法报错");
          }
        }
      }
    },

    /*
     *@描述: 三方回调取款结果页面后，需要调用此方法，强制手机在返回的时候返回咱们APP的首页
     *@参数: 无
     *@作者: 金涛
     *@日期: 2022-08-10 18:12:28
     */
    goPageParent() {
      const { client } = this;
      if (client === "ios") {
        try {
          this.$bridge.callhandler("popWeb");
        } catch (err) {
          console.log("try---catch-调用IOS客户端方法报错");
        }
      } else if (client === "android") {
        if (window.LoanMarketChina) {
          try {
            window.LoanMarketChina.popWeb();
          } catch (err) {
            console.log("try---catch-调用安卓客户端方法报错");
          }
        }
      }
    },
    /**
     * 调用客户端方法跳转客户端页面
     * @param {*} pageType 默认参数是home， 前端自定义类型，home代表首页，进行switch判断，根据不同机型返回不同参数文案，后期添加别的页面类型参数，可在switch自行配置
     */
    go2AppNativePage(pageType = "home") {
      const { client } = this;
      let pageObj = {
        ios: "",
        android: "",
      };
      // 根据不同类型，赋值不同参数值
      switch (pageType) {
        case "home":
          pageObj.ios = "YCHomeViewController";
          pageObj.android = "homeV2Fragment";
          break;
      }
      if (client === "ios") {
        try {
          // 根据不同类型获取不同参数值
          const pageName = pageObj.ios;
          this.$bridge.callhandler("go2AppNativePage", { pageName });
        } catch (err) {
          console.log("try---catch-调用IOS客户端方法报错");
        }
      } else if (client === "android") {
        if (window.LoanMarketChina) {
          try {
            // 根据不同类型获取不同参数值
            const pageName = pageObj.android;
            window.LoanMarketChina.go2AppNativePage(pageName);
          } catch (err) {
            console.log("try---catch-调用安卓客户端方法报错");
          }
        }
      }
    },
  },
};
