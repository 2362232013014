import Bridge from "@/utils/bridge";
import Vant from "vant";
import "vant/lib/index.css";
import Vue from "vue";
import App from "./App.vue";
import "./assets/styles/base.scss";

// 全局组件引入
import SharePop from "@/components/SharePop"; // 浏览器打开全局弹窗组件
import CountTo from "vue-count-to";

// 全局方法引入
import { numberFormat } from "@/utils/function";

import router from "./router";
import store from "./store";
import mixin from "@/utils/mixin";
import "@/utils/rem";

// 全局组件挂载
Vue.component("CountTo", CountTo);
Vue.component("SharePop", SharePop);

// 全局方法挂载
Vue.prototype.numberFormat = numberFormat;

Vue.config.productionTip = false;

Vue.use(Vant);
Vue.mixin(mixin);

// 挂载到Vue原型链上
Vue.prototype.$bridge = Bridge;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

// 加入百度统计
router.beforeEach((to, from, next) => {
  if (to.path) {
    if (window._hmt) {
      window._hmt.push(["_trackPageview", "/#" + to.fullPath]);
    }
  }
  next();
});
