import Storage from "@/utils/storage";
import Vue from "vue";
import Vuex from "vuex";

let token = Storage.getItem("token");

if (!token) {
  token = "";
}

let cookieId = Storage.getItem("cookieId");

if (!cookieId) {
  cookieId = "";
}

let jwt = Storage.getItem("jwt");

if (!jwt) {
  jwt = "";
}

Vue.use(Vuex);

const SET_TOKEN = "SET_TOKEN";
const DELETE_TOKEN = "DELETE_TOKEN";
const SET_COOKIEID = "SET_COOKIEID";
const DELETE_COOKIEID = "DELETE_COOKIEID";
const SET_JWT = "SET_JWT";
const DELETE_JWT = "DELETE_JWT";

// 计算cookieId过期时间并且当日23:59:59秒有效
function cumpotedExpiration() {
  let curDate = new Date();
  let curTamp = curDate.getTime(); //当前时间戳
  let curWeeHours = new Date(curDate.toLocaleDateString()).getTime() - 1; //当日凌晨的时间戳,减去一毫秒是为了防止后续得到的时间不会达到00:00:00的状态
  let passedTamp = curTamp - curWeeHours; //当日已经过去的时间（毫秒）
  let leftTamp = 24 * 60 * 60 * 1000 - passedTamp; //当日剩余时间

  return new Date(new Date() * 1 + leftTamp);

  // 测试2分钟失效
  // return new Date(new Date() * 1 + 120 * 1000);
}

export default new Vuex.Store({
  state: {
    token: token,
    cookieId: cookieId,
    jwt: jwt,
  },
  mutations: {
    [SET_TOKEN](state, payload) {
      state.token = payload;
    },
    [DELETE_TOKEN](state) {
      state.token = "";
    },
    [SET_COOKIEID](state, payload) {
      state.cookieId = payload;
    },
    [DELETE_COOKIEID](state) {
      state.cookieId = "";
    },
    [SET_JWT](state, payload) {
      state.jwt = payload;
    },
    [DELETE_JWT](state) {
      state.jwt = "";
    },
  },
  actions: {
    setToken({ commit }, payload) {
      Storage.setItem("token", payload);
      commit("SET_TOKEN", payload);
    },
    deleteToken({ commit }) {
      commit("DELETE_TOKEN");
    },
    setCookieId({ commit }, payload) {
      Storage.setItem("cookieId", payload, {
        expires: cumpotedExpiration(),
      });
      commit("SET_COOKIEID", payload);
    },
    deleteCookieId({ commit }) {
      commit("DELETE_COOKIEID");
    },
    setJwt({ commit }, payload) {
      Storage.setItem("jwt", payload);
      commit("SET_JWT", payload);
    },
    deleteJwt({ commit }) {
      commit("DELETE_JWT");
    },
  },
  modules: {},
});
