/**
 *
 */
/*
 *@描述: 通用js方法封装处理
 *@参数: 无
 *@作者: 金涛
 *@日期: 2022-10-17 22:39:47
 */

/*
 *@描述: 金额格式化 （1234567.089, "¥") => 1,234,567.08
 *@参数: {
  money
  symbol：金额符号
  tofixed: 保留几位
 }
 *@作者: 金涛
 *@日期: 2022-04-12 16:16:39
*/
export function numberFormat(money, symbol, tofixed) {
  symbol = symbol || "￥"; // 默认是RMB
  let str = money.toFixed(tofixed); // 只取2位小数
  let l = str.split(".")[0]; // 获取整数位
  let r = str.split(".")[1] || ""; // 获取小数位,如果不保留两位小数则为''
  let arr = []; // 用于保存结果

  let len = Math.ceil(l.length / 3); // 3位数一个 `,`
  for (let i = 0; i < len; i++) {
    arr.unshift(l.slice(-3 * (i + 1), -3 * i || undefined)); // 如果传(-3,0)获取不到参数，将0换成undefined相当于没传
    if (i !== len - 1) {
      // 最后一次截取不加 `,`了
      arr.unshift(",");
    }
  }
  return symbol + arr.join("") + (tofixed ? "." : "") + r;
}
